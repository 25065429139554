const options = {
    container: 'luckysheet', //luckysheet为容器id
    lang: 'zh',
    title: '',
    showinfobar: false,
    showtoolbar: false,
    showtoolbarConfig: {
        undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
        font: true, // '字体'
        fontSize: true, // '字号大小'
        bold: true, // '粗体 (Ctrl+B)'
        italic: true, // '斜体 (Ctrl+I)'
        //strikethrough: true, // '删除线 (Alt+Shift+5)'
        underline: true, // '下划线 (Alt+Shift+6)'
        textColor: true, // '文本颜色'
        fillColor: true, // '单元格颜色'
        border: true, // '边框'
        image: true,
        mergeCell: true, // '合并单元格'
        horizontalAlignMode: true, // '水平对齐方式'
        verticalAlignMode: true, // '垂直对齐方式'
        print: false, // '打印'
    },
    column: 50,
    row: 50,
    showstatisticBar: false,
    enableAddRow: false,
    enableAddBackTop: false,
    userInfo: false,
    showsheetbar: false,
    cellRightClickConfig: {
        copy: true, // 复制
        copyAs: false, // 复制为
        paste: true, // 粘贴
        insertRow: true, // 插入行
        insertColumn: true, // 插入列
        deleteRow: true, // 删除选中行
        deleteColumn: false, // 删除选中列
        deleteCell: false, // 删除单元格
        hideRow: false, // 隐藏选中行和显示选中行
        hideColumn: false, // 隐藏选中列和显示选中列
        rowHeight: true, // 行高
        columnWidth: true, // 列宽
        clear: false, // 清除内容
        matrix: false, // 矩阵操作选区
        sort: false, // 排序选区
        filter: false, // 筛选选区
        chart: false, // 图表生成
        image: true, // 插入图片
        link: false, // 插入链接
        data: false, // 数据验证
        cellFormat: false // 设置单元格格式
    },
    sheetFormulaBar: false
}
export default options;
