<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-03-31 11:54:31
 */
import options from '../../../utils/excel.js'
import ltree from './ltree/ltree';

export default {
  components: {ltree},
  data() {
    return {
      page_options: false,
      edit_type: 1,
      type: 3,
      options: options,
      moban_data_list: [],
      moban_list: [],
      moban_info: {id: 0, name: ''},
      tr_arr: ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      create_data: {
        range: '',
        value: [],
      },
      datalist: []
    }
  },
  mounted() {
    this.mountedDo();
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    },
  },
  methods: {
    deldata(key) {
      this.datalist.splice(key, 1)
    },
    createdata() {
      this.datalist.push(this.create_data)
      this.create_data = {
        range: '',
        value: [],
      }
    },
    returnYpcontentXy(xy) {
      if (xy === '') return '';
      let xy_ = JSON.parse(xy)
      let t1 = `${this.tr_arr[xy_.column[0] + 1]}${xy_.row[0] + 1}`;
      let t2 = `${this.tr_arr[xy_.column[1] + 1]}${xy_.row[1] + 1}`;
      if (t1 === t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    mountedDo() {
      this.page_options = this.$route.query
      this.edit_type = this.page_options.type ? Number(this.page_options.type) : 1
      this.getMubanList()
      this.getMubanInfo()
      this.getDataTypeList()
      this.getdatalist()
    },
    pageJump(id) {
      this.$router.push({
        name: this.$route.name,
        query: {id}
      })
    },
    getMubanList() {
      this.$sa0.post({
        url: this.$api('三个模板：获取模板列表'),
        data: {
          type: this.type
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let list = this.makeList(response.data.list)
            list.unshift({
              'id': 0,
              "type": 1,
              "name": '新建模板',
              "children": [],
              "content": false
            })
            this.moban_list = list
            setTimeout(() => {
              this.$refs['ltreeref'].active_arr = [Number(this.page_options.id) || 0]
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    treeCallback(arr) {
      this.pageJump(arr[0].id)
    },
    makeList(list) {
      return list.map((item) => {
        return {
          'id': Number(item.id),
          "type": 1,
          "name": item.name,
          "children": [],
          "content": false
        }
      })
    },
    editMubanInfo() {
      let api = this.$api('三个模板：修改模板')
      if (this.moban_info.id === 0) api = this.$api('三个模板：创建模板')
      this.$sa0.post({
        url: api,
        data: {
          type: this.type,
          id: this.moban_info.id,
          name: this.moban_info.name,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (this.moban_info.id === 0) {
              this.pageJump(response.data.id)
            } else {
              window.location.reload()
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getDataTypeList() {
      this.$sa0.post({
        url: this.$api('三个模板：获取模板数据信息选项'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.moban_data_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getMubantable(id) {
      this.$sa0.post({
        url: this.$api('三个模板：获取模板表格'),
        data: {
          id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = response.data.info
            this.pushExcelJson(table ? {sheets: [JSON.parse(table).data[0]]} : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getMubanInfo() {
      if (this.page_options.id && Number(this.page_options.id) !== 0) {
        this.$sa0.post({
          url: this.$api('三个模板：获取模板信息'),
          data: {
            id: this.page_options.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.moban_info = response.data.info
              if (this.moban_info.table === 1) {
                this.getMubantable(this.page_options.id)
              } else {
                setTimeout(() => {
                  this.getMubantable(0)
                })
              }
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.moban_info = {id: 0, name: ''}
      }
    },
    pushExcelJson(exportJson = false) {
      let options = this.options
      if (exportJson) {
        options.data = exportJson.sheets
      } else {
        if (this.edit === 2) {
          this.routerPush(this.list_search.page, this.list_search.search, this.$route.params.id, 1)
        }
      }
      luckysheet.destroy();
      luckysheet.create(options);
    },
    saveTableInfo() {
      let this_ = this;
      layer.confirm('保存表格结构可能会影响动态数据和区域的设置', {
        btn: ['保存', '取消'] //按钮
      }, function () {
        this_.saveDo()
      }, function () {
      });
    },
    saveDo() {
      let this_ = this;
      let sheet = luckysheet.toJson()
      this_.$sa0.post({
        url: this_.$api('三个模板：上传模板'),
        data: {
          type: this.type,
          id: this.moban_info.id,
          content: JSON.stringify(sheet),
        }
      }).then((response) => {
        this_.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    changeType() {
      this.$router.push({
        name: this.$route.name,
        query: {id: this.moban_info.id, type: this.edit_type === 1 ? 2 : 1}
      })
    },
    uploadExcel(e) {
      let files = e.target.files;
      if (files == null || files.length == 0) return layer.msg('请上传文件')
      let name = files[0].name;
      let suffixArr = name.split("."), suffix = suffixArr[suffixArr.length - 1];
      if (suffix != "xlsx") return layer.msg('请上传xlsx文件')
      let this_ = this
      LuckyExcel.transformExcelToLucky(files[0], function (exportJson, luckysheetfile) {
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              return layer.msg('请上传xlsx文件')
            }
            this_.pushExcelJson(exportJson)
          }
      )
    },
    readRangeline(key) {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.datalist[key].range = range_str
    },
    readRange() {
      let range = luckysheet.getRange()
      if (range.length > 1) return layer.msg('只能选择一个单元格')
      let range_str = JSON.stringify(range[0])
      this.create_data.range = range_str
    },
    getdatalist() {
      // 三个模板：获取模板数据列表
      this.$sa0.post({
        url: this.$api('三个模板：获取模板数据列表'),
        data: {
          id: this.page_options.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.datalist = response.data.list.map((item) => {
              return {
                range: item.range,
                value: JSON.parse(item.data)
              }
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    savedatalist() {
      // 三个模板：保存数据列表
      this.$sa0.post({
        url: this.$api('三个模板：保存数据列表'),
        data: {
          id: this.page_options.id,
          type: this.type,
          datalist: this.datalist,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
            this.getdatalist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <a-space align="start">
      <div class="moban_list_wrapper">
        <ltree ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="moban_list" all="all1" :all_menu="[]"
               :menu="[]" :callback="treeCallback"></ltree>
      </div>
      <div class="excel_wrapper">
        <div class="excel_input_wrapper">
          <a-space>
            <div><b>模板名称：</b></div>
            <div>
              <a-input style="width: 400px;" v-model="moban_info.name"></a-input>
            </div>
            <div>
              <a-button type="primary" @click="editMubanInfo()">{{ moban_info.id === 0 ? '新建' : '修改' }}</a-button>
            </div>
          </a-space>
        </div>
        <div class="excel_table_wrapper">
          <div v-if="moban_info.id !== 0" id="luckysheet" class="luckysheet_wrapper"></div>
        </div>
      </div>
      <div v-if="moban_info.id !== 0" class="button_wrapper">
        <div>
          <a-space align="start">
            <!--            <a-button type="primary" @click="changeType()"> {{ edit_type === 1 ? '切换为数据编辑' : '切换为表格编辑' }}</a-button>-->
            <div v-if="edit_type === 1" class="upload_wrapper">
              <input @change="uploadExcel" type="file" class="upload_input_wrapper">
              <a-button type="primary">上传本地表格</a-button>
            </div>
            <a-button v-if="edit_type === 1" type="primary" @click="saveTableInfo()">保存表格结构</a-button>
          </a-space>
        </div>
        <div>
          <table style="width: 400px;" class="layui-table" lay-size="sm">
            <tbody>
            <tr>
              <td>位置</td>
              <td>数据</td>
              <td>操作</td>
            </tr>
            <tr>
              <td width="100">{{ returnYpcontentXy(create_data.range) }}</td>
              <td>
                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                            v-model="create_data.value" :options="moban_data_list" placeholder="请选择" class="w200"/>
              </td>
              <td>
                <a-space>
                  <a-button @click="readRange()">读取位置</a-button>
                  <a-button @click="createdata()">创建</a-button>
                </a-space>
              </td>
            </tr>
            </tbody>
          </table>

          <table style="width: 400px;" class="layui-table" lay-size="sm">
            <tbody>
            <tr>
              <td>位置</td>
              <td>数据</td>
              <td>操作</td>
            </tr>
            <tr v-for="(li,lk) in datalist">
              <td width="100">{{ returnYpcontentXy(li.range) }}</td>
              <td>
                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                            v-model="li.value" :options="moban_data_list" placeholder="请选择" class="w200"/>
              </td>
              <td>
                <a-space>
                  <a-button @click="readRangeline(lk)">读取位置</a-button>
                  <a-button @click="deldata(lk)">删除</a-button>
                </a-space>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <a-button @click="savedatalist()">保存</a-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </a-space>
  </div>
</template>
<style>

</style>
<style scoped>
.upload_input_wrapper {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
}

.luckysheet_wrapper {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
}

.upload_wrapper {
  position: relative;
  width: 104px;
}

.excel_wrapper {
  position: relative;
  width: 600px;
  height: calc(100vh - 140px);
}

.moban_list_wrapper {
  width: 250px;
}
</style>
